.ccc__features-container__feature {
   width: 100%;
   display: flex; 
   justify-content: space-between;
   align-items: flex-start;
   flex-direction: row;
   margin: 1rem;
}

.ccc__features-container__feature-title {
    flex: 1;
    max-width: 400px;
    margin-right: 2rem;
}

.ccc__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 24px;
    line-height: 75px; 
    /* color: var(--color-subtext); */
    /* letter-spacing: -0.04em; */
    color: rgb(44, 196, 234);
    cursor: pointer;

}

.ccc__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    margin-bottom: 0.25rem;
}

.ccc__features-container__feature-text {
    flex: 2;
    max-width: 400px;
    display: flex;
}

.ccc__features-container__feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px; 
    color: rgb(31, 179, 80);
}

@media screen and (max-width: 550px) {
    .ccc__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .ccc__features-container__feature-title p {
        font-size: 10px;
        line-height: 18px;
    } 

    .ccc__features-container__feature {
        margin: 1rem 0;
    }
}