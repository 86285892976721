.ccc__blog-gradient__text {
    background-image: -webkit-linear-gradient(#42d1f1, #14ed92);
    background-clip: text;
    color: transparent;
}

.ccc__blog {
    display: flex;
    flex-direction: column;
}

.ccc__blog-heading {
    width: 100%;
    text-align: left;

    margin-bottom: 5rem;
}

.ccc__blog-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px; 
    letter-spacing: -0.04em;

    display: flex;
    justify-content: center;
    align-items: center;
}

.ccc__blog-container {
    display: flex;
    flex-direction: row;
}

.ccc__blog-container__groupA {
    flex: 0.75;
    margin-right: 2rem;
}

.ccc__blog-container__groupB {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

.ccc__blog-container__groupB .ccc__blog-container__article-image {
    height: 50%;
}

@media screen and (max-width: 990px) {
    .ccc__blog-container {
        flex-direction: column-reverse;
    }

    .ccc__blog-container__groupA {
        margin: 2rem 0;
    }
    
    .ccc__blog-container__groupA .ccc__blog-container__article {
        width: 48%;    
    }

    .ccc__blog-container__groupA .ccc__blog-container__article-image {
        height: 250px;   
    }
}

@media screen and (max-width: 700px) {
    .ccc__blog-container__groupB {
        grid-template-columns: repeat(1, 1fr);
    }

    .ccc__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {
    .ccc__blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }
}
