.ccc__possibility-gradient__text {
    background-image: -webkit-linear-gradient(#f14291, #14c9ed);
    background-clip: text;
    color: transparent;
}

.ccc__possibility {
    display: flex;
    flex-direction: row;
}

.ccc__possibility-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.ccc__possibility-image img {
    width: 500px;
    height: 800px;
}

.ccc__possibility-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.ccc__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;

    color: #f34a96;
    margin-bottom: 1rem;
}

.ccc__possibility-content h4:last-child {
    color: #1f3dff;
}

.ccc__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
}

.ccc__possibility-content p {
    font-family: var(--font-family);
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;

    background-image: -webkit-linear-gradient(#ee0760, #1c8e32, #14c9ed);
    background-clip: text;
    color: transparent;

    margin-bottom: 2rem;
    margin-top: 1rem;
}

@media screen and (max-width: 950px) {
   .ccc__possibility {
    flex-direction: column;
   } 
   
   .ccc__possibility-image {
    margin: 1rem 0;
   }

   .ccc__possibility-content {
    margin-top: 2rem;
   }
}

@media screen and (max-width: 650px) {
    .ccc__possibility-image {
        justify-content: center;
        align-items: center;
    }
    .ccc__possibility-image img {
        width: 300px;
        height: 600px;
    }
}

/* @media screen and (max-width: 490px) {
    .ccc__possibility-image img {
        width: 250px;
        height: 500px;
    }
} */