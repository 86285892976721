* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg)
}

a {
    color: unset;
    text-decoration: none;
}

/* Made from https://angrytools.com/gradient/ */
.gradient__bg {
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 3% 25%, rgba(2, 201, 255, 1) 0%, rgba(238, 130, 238, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 3% 25%, rgba(2, 201, 255, 1) 0%, rgba(238, 130, 238, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 3% 25%, rgba(2, 201, 255, 1) 0%, rgba(238, 130, 238, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 3% 25%, rgba(2, 201, 255, 1) 0%, rgba(238, 130, 238, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 3% 25%, rgba(2, 201, 255, 1) 0%, rgba(238, 130, 238, 1) 100%);
}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-test-fill-color: transparent;
}

.whatccc__gradient-bg {

  /* ff 3.6+ */
  background:-moz-linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(237, 3, 11, 1) 47%, rgba(17, 142, 213, 1) 86%); 

  /* safari 5.1+,chrome 10+ */
  background:-webkit-linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(237, 3, 11, 1) 47%, rgba(17, 142, 213, 1) 86%);

  /* opera 11.10+ */
  background:-o-linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(237, 3, 11, 1) 47%, rgba(17, 142, 213, 1) 86%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#118ED5', endColorstr='#FFFF00', GradientType=0 );

  /* ie 10+ */
  background:-ms-linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(237, 3, 11, 1) 47%, rgba(17, 142, 213, 1) 86%);

  /* global 94%+ browsers support */
  background:linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(237, 3, 11, 1) 47%, rgba(17, 142, 213, 1) 86%);
}

.possibility__gradient-bg {

  /* ff 3.6+ */
  background:-moz-linear-gradient(90deg, rgba(0, 255, 4, 1) 0%, rgba(238, 130, 238, 1) 30%, rgba(205, 220, 57, 1) 68%, rgba(0, 188, 212, 1) 100%); 

  /* safari 5.1+,chrome 10+ */
  background:-webkit-linear-gradient(90deg, rgba(0, 255, 4, 1) 0%, rgba(238, 130, 238, 1) 30%, rgba(205, 220, 57, 1) 68%, rgba(0, 188, 212, 1) 100%);

  /* opera 11.10+ */
  background:-o-linear-gradient(90deg, rgba(0, 255, 4, 1) 0%, rgba(238, 130, 238, 1) 30%, rgba(205, 220, 57, 1) 68%, rgba(0, 188, 212, 1) 100%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00BCD4', endColorstr='#00FF04', GradientType=0 );

  /* ie 10+ */
  background:-ms-linear-gradient(90deg, rgba(0, 255, 4, 1) 0%, rgba(238, 130, 238, 1) 30%, rgba(205, 220, 57, 1) 68%, rgba(0, 188, 212, 1) 100%);

  /* global 94%+ browsers support */
  background:linear-gradient(90deg, rgba(0, 255, 4, 1) 0%, rgba(238, 130, 238, 1) 30%, rgba(205, 220, 57, 1) 68%, rgba(0, 188, 212, 1) 100%);

}

.possibility__gradient-bg__v2 {

  /* ff 3.6+ */
  background:-moz-linear-gradient(90deg, rgba(0, 255, 4, 1) 0%, rgba(238, 130, 238, 1) 49%, rgba(230, 226, 25, 1) 100%); 

  /* safari 5.1+,chrome 10+ */
  background:-webkit-linear-gradient(90deg, rgba(0, 255, 4, 1) 0%, rgba(238, 130, 238, 1) 49%, rgba(230, 226, 25, 1) 100%);

  /* opera 11.10+ */
  background:-o-linear-gradient(90deg, rgba(0, 255, 4, 1) 0%, rgba(238, 130, 238, 1) 49%, rgba(230, 226, 25, 1) 100%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E6E219', endColorstr='#00FF04', GradientType=0 );

  /* ie 10+ */
  background:-ms-linear-gradient(90deg, rgba(0, 255, 4, 1) 0%, rgba(238, 130, 238, 1) 49%, rgba(230, 226, 25, 1) 100%);

  /* global 94%+ browsers support */
  background:linear-gradient(90deg, rgba(0, 255, 4, 1) 0%, rgba(238, 130, 238, 1) 49%, rgba(230, 226, 25, 1) 100%);
}

.footer__gradient-bg {

  /* ff 3.6+ */
  background:-moz-linear-gradient(90deg, rgba(236, 92, 240, 1) 0%, rgba(4, 193, 189, 1) 100%); 

  /* safari 5.1+,chrome 10+ */
  background:-webkit-linear-gradient(90deg, rgba(236, 92, 240, 1) 0%, rgba(4, 193, 189, 1) 100%);

  /* opera 11.10+ */
  background:-o-linear-gradient(90deg, rgba(236, 92, 240, 1) 0%, rgba(4, 193, 189, 1) 100%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#04C1BD', endColorstr='#EC5CF0', GradientType=0 );

  /* ie 10+ */
  background:-ms-linear-gradient(90deg, rgba(236, 92, 240, 1) 0%, rgba(4, 193, 189, 1) 100%);

  /* global 94%+ browsers support */
  background:linear-gradient(90deg, rgba(236, 92, 240, 1) 0%, rgba(4, 193, 189, 1) 100%);

}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    padding: 4rem 6rem;
}

/* Text animations from https://animista.net/play/basic/scale-up/ */
.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.black__bg {
  background: #000000;
}

 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  
.rotate-diagonal-1 {
	-webkit-animation: rotate-diagonal-1 0.4s linear both;
	        animation: rotate-diagonal-1 0.4s linear both;
}

 @-webkit-keyframes rotate-diagonal-1 {
    0% {
      -webkit-transform: rotate3d(1, 1, 0, 0deg);
              transform: rotate3d(1, 1, 0, 0deg);
    }
    50% {
      -webkit-transform: rotate3d(1, 1, 0, -180deg);
              transform: rotate3d(1, 1, 0, -180deg);
    }
    100% {
      -webkit-transform: rotate3d(1, 1, 0, -360deg);
              transform: rotate3d(1, 1, 0, -360deg);
    }
  }
  @keyframes rotate-diagonal-1 {
    0% {
      -webkit-transform: rotate3d(1, 1, 0, 0deg);
              transform: rotate3d(1, 1, 0, 0deg);
    }
    50% {
      -webkit-transform: rotate3d(1, 1, 0, -180deg);
              transform: rotate3d(1, 1, 0, -180deg);
    }
    100% {
      -webkit-transform: rotate3d(1, 1, 0, -360deg);
              transform: rotate3d(1, 1, 0, -360deg);
    }
  }
  
.shadow-drop-2-center {
	-webkit-animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes shadow-drop-2-center {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-transform: translateZ(50px);
              transform: translateZ(50px);
      -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
              box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }
  @keyframes shadow-drop-2-center {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-transform: translateZ(50px);
              transform: translateZ(50px);
      -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
              box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }

  /* Size for tablets or big phones */
  @media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }

    .section__margin {
        margin: 4rem;
    }
  }

  /* Size for tmobile devices */
  @media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }

    .section__margin {
        margin: 4rem 2rem;
    }
  }
  