  /* Made from https://angrytools.com/gradient/ */
.ccc__whatccc {
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

.ccc__whatccc-feature {
    display: flex;
}

.ccc__whatccc-feature .ccc__features-container__feature {
    margin: 0;
}

.ccc__whatccc-feature .ccc__features-container__feature-text {
    max-width: 700px;
}

.ccc__whatccc-heading {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center; 

   margin: 4rem 0 2rem;
}

.ccc__whatccc-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px; 
    max-width: 510px; 

}

.ccc__whatccc-gradient__text{
    background-image: -webkit-linear-gradient(#068827, #3e0588);
    background-clip: text;
    color: transparent;
}

.ccc__whatccc-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px; 
    color: var(--color-subtext);
    cursor: pointer;
} 

.ccc__whatccc-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.ccc__whatccc-container .ccc__features-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.ccc__whatccc-container .ccc__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .gpt3__whatgpt3-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .ccc__whatccc-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .ccc__whatccc-feature .ccc__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .ccc__whatccc-feature .ccc__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .ccc__whatccc-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }

    .ccc__whatccc-heading p {
        color: transparent;
        cursor: auto;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .ccc__whatccc-container .ccc__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}