.ccc__blog-container__article {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: #82e1fb;
}

.ccc__blog-container__article-image {
    width: 100%;
    height: 100%;

    background: #82e1fb;
}

.ccc__blog-container__article-image img {
    width: 100%;
    height: 100%;
}

.ccc__blog-container__article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.ccc__blog-container__article-content p {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 12px;
    line-height: 35px;
    color:#0e0e0e;
}

.ccc__blog-container__article-content h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    color:#000000;

    margin-bottom: 5rem;
    cursor: pointer;
}

.ccc__blog-container__article-content p:last-child {
    cursor: pointer
}

@media screen and (max-width: 550px) {
    .ccc__blog-container__article-content h3 {
        font-size: 18px;
        line-height: 25px;
    }
}